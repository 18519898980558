@import url(https://fonts.googleapis.com/css?family=Kanit:300,400,500,600,700,900&display=swap);
.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.img-menu {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}
.header-wrap {
    height: auto !important;
}
.menu-button {
    padding: 10px;
    display: block;
    width: auto;
    position: absolute;
    color: white;
    line-height: normal;
    font-size: 16px;
    cursor: pointer;
}
.header-wrap .point-button {
    padding: 10px;
    display: block;
    width: auto;
    position: absolute;
    color: white;
    line-height: normal;
    font-size: 16px;
    cursor: pointer;
    top:0;
    right: 0;
}
.menu-button .material-icons{
    font-size: 36px;
}
.logo-buttom{
    position: absolute;
    width: 100%;
    bottom: 5%;
}
.link-menu{
    color: #000000;
    font-weight: 500;
}
.menu-box li.ant-menu-item-selected{
    background-color: rgb(1, 166, 157) !important;
}
.menu-box li.ant-menu-item-selected > a{ 
 color: #FFFFFF !important;
}
.layout-point{
    position: absolute;
    box-sizing: border-box;
    /* background-color: black; */
    /* box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12); */
    padding: 5px 10px;
    border-radius: 23px;
    z-index: 2;
    display: inline-block;
    left: 80%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top:0;
    background-color: #ffffff;
}
.layout-point h4{
    display: inline-block;
    margin-bottom: 0;
    font-size: 12px;
}
.header-wrap .point-icon{
    background-color: #FFD93E;
    color: white;
    line-height: 1.1;
    display: inline-block;
    margin-right: 10px;
    border-radius: 50%;
    padding: 0px 3px 0px 3px
}
.header-wrap .point-icon i{
    font-size: 11px;
}
.header-wrap .point-material{
    position: relative;
    box-sizing: border-box;
    /* box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12); */
    padding: 7px 10px;
    border-radius: 23px;
    z-index: 2;
    display: inline-block;
    line-height: 1.1;
    background-color: #ffffff;
}
.header-wrap .point-material h4{
    font-size: 14px;
}
.btn{
    outline: none;
    font-size: 18px;
    text-align: center;
    position: relative;
    box-sizing: border-box;
    transition: all 0.2s ease-out;
    cursor: pointer;
    padding: 0.6rem 1rem;
    line-height: normal;
}
.btn.disabled{
    opacity: 0.65px;
    pointer-events: none;
}
.btn.btn-block{
    width: 100%;
}
.btn-wrapper{
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
}
.btn-bottom{
    width: calc(100% - 30px);
    margin-top: 15px;
    margin-bottom: 15px;
}
.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.card-form{
    padding-top: 45px;
    position: relative;
}
.container{
    padding: 0px 15px;
    background-color: '#FFFFFF';
}
.font-title{
    padding-top: 30px;
    font-size: 34px;
    font-weight: 500;
    line-height: 1.25;
}
.text-center{
    text-align: center;
}
.text-center h4{
    font-size: 18px;
    font-weight: 100;
} 
.img-gift{
    padding-top: 80px;
}
.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.card-register .avatar__user{
    position: absolute;
    text-align: center;
    margin-bottom: 30px;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: -89px;
    left: 50%;
}
.card-register .edit-icon{
    position: absolute;
    top: 20px;
    padding: 4px 6px;
    background-color: #000000;
    right: -8px;
    border-radius: 50%;
    line-height: normal;
    color: white;
    border: 2px solid;
    font-size: 12px;
}
.edit-icon .material-icons{
    font-size: 16px;
    z-index: 5;
}
.upload-button{
    display: none;
    background: transparent;
    color: white;
    border: 0;
}
.user-photo{
    z-index: 4;
}
.avatar-user{
    border: 4px solid;
}
.card-register{
    padding-top: 80px;
}
.container{
    padding: 0px 15px;
    background-color: '#FFFFFF';
}
.font-title{
    padding-top: 30px;
    font-size: 34px;
    font-weight: 500;
    line-height: 1.25;
}
.text-center{
    text-align: center;
}
.text-center h4{
    font-size: 18px;
    font-weight: 100;
} 
.img-gift{
    padding-top: 80px;
}
.register-wrapper .card-register .ant-input{
    height: 40px;
    font-size: 16px;
    padding: 5px 11px;
    background-color: #F4F4F4;
    border: 0;
    padding-left: 47px !important;
}
.input-phonecode{
    height: 40px;
    font-size: 16px;
    padding: 5px 11px;
    background-color: #F4F4F4;
    border: 0;
}
.card-register .ant-form-item{
    margin-bottom: 5px;
}
.btn-gender span{
    font-size: 14px;
}
.register-modal h3{
    text-align: center;
    font-size: 18px;
}
.sendcode-modal h3{
    font-size: 18px;
    color: #6B6B6B;
    font-weight: 200;
}
.sendcode-modal {
    font-size: 18px;
    color: #6B6B6B;
    font-weight: 200;
}
/* .sendcode-modal.modal-dialog-scrollable .ant-modal-content {
    max-height: calc(100% - 1rem);
    display: flex;
} */

@media only screen and (max-width: 568px) {
    .sendcode-modal.modal-dialog-scrollable .ant-modal-content {
        max-width: calc(100% - 2rem);
        margin: 0 auto;
    }
}

.sendcode-modal.modal-dialog-scrollable .modal-body-content {
    overflow-y: auto;
}
.sendcode-modal h2{
    font-size: 18px;
    font-weight: 400;
}
.sendcode-modal h1{
    font-size: 26px;
    font-weight: 300;
}
.has-error .ant-input-prefix{
    color: #6B6B6B;
}
.has-error .ant-input-suffix{
    color: rgb(246, 0, 0);
}
.has-error .ant-input-affix-wrapper .ant-input, .has-error .ant-input-affix-wrapper .ant-input:hover{
    background-color: #F4F4F4;
    border: 2 solid;
    border: 2px solid #f5222d !important;
}
.ant-form-explain{
    margin-top: 5px;
    margin-bottom: 10px;
}
.has-error .ant-form-explain, .has-error .ant-form-split{
    margin: 5 0;
    text-align: center;
    font-weight: 500;
}
.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled){
    border: 2px solid;
    border-color: #f5222d;
}
.has-error .ant-input-affix-wrapper .ant-input:not(.ant-input-disabled){
    border: 2px solid;
    border-color: #f5222d;
    padding-left: 47px;
}
.fav-wrapper{
    padding: 20px;
}
.fav-title{
    margin-top: -5px;
    text-align: center;
    font-size: 16px;
}
.point-tap{
    width: 100%;
}
.fav-tap-wrapper{
    margin-top: 20px;
}
.fav-wrapper .fav-tap-wrapper .ant-tabs-nav{
    width: 100% !important;
}
.fav-wrapper .fav-tap-wrapper .ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane{
    background-color: #F4F4F4;
}
.fav-wrapper .fav-tap-wrapper .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
    text-align: center;
    width: 50% !important;
    margin-right: 0 !important;
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-weight: 500;
    background-color: rgb(255, 255, 255);
    border-bottom-color: transparent;
}
.fav-wrapper .fav-tap-wrapper .ant-tabs.ant-tabs-card .ant-tabs-card-bar{
    background-color: #ffffff !important;
    margin-bottom: 0;
}
.fav-wrapper .fav-tap-wrapper .ant-tabs-tab{
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
}
.fav-wrapper .fav-tap-wrapper .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
    background-color: transparent !important;
    color: #000000 !important;
    font-size: 18px;
    border-bottom: 3px solid;
    border-bottom-color: #FECE00;
}
.fav-wrapper .fav-tap-wrapper .ant-tabs-nav .ant-tabs-ink-bar{
 background-color: transparent !important;
}
.fav-buttom{
    text-align: center;
}
.fav-tap-wrapper .ant-card-bordered{
    border: 0;
}
.fav-tap-wrapper .ant-card-bordered .ant-card-body{
    padding: 0px;
}
.fav-tap-wrapper .ant-col{
    text-align: center;
}
.card-select span{
    font-size: 14px;
    font-weight: 400;
}
.card-select p{
    font-size: 18px;
}
.card-select{
    padding: 10px;
}
.ant-input-group-addon .ant-btn-primary{
    color: #6B6B6B;
    background-color: #ffffff;
    border-color: #D1D1D1;
    height: 40px;
    font-size: 18px;
}
.fav-search .ant-input{
    height: 40px;
    font-size: 16px;
    padding: 5px 11px;
    border: 1 solid;
}
.fav-wrapper .fav-tap-wrapper .ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane{
    background-color: transparent;
}
.artist-name-card{
    padding: 15px;
    /* height: 420px;
    overflow: scroll; */
}
@media only screen and (min-width: 700px) {
    .artist-name-card{
        padding: 15px;
        /* height: 625px !important;
        overflow: scroll; */
    }
  }
@media only screen and (min-width: 1000px) {
    .artist-name-card{
        padding: 15px;
        /* height: 940px !important;
        overflow: scroll; */
    }
}
.ant-upload-list.ant-upload-list-text{
    display: none;
}
.born-text{
    position: absolute;
    z-index: 5;
    left: 50px;
    font-size: 16px;
    font-weight: 400;
}
input[type="date"]#register_born {
    height: 40px;
    line-height: 2;
  }
input[type="date"]#register_born:before {
    content: attr(placeholder) !important;
    color: #aaa;
    height: 40px;
    line-height: 2;
    z-index: 1;
  }
input[type="date"]#register_born:focus:before,
input[type="date"]#register_born:valid:before {
  content: "";
}
.input-city .ant-select-selection--single{
    position: relative;
    cursor: pointer;
    height: 40px;
    font-size: 16px;
    padding: 5px 11px;
    background-color: #F4F4F4;
    border: 0;
}
.input-city .ant-select-selection__placeholder, .ant-select-search__field__placeholder{
    color: #6B6B6B;
}
.gender-from .has-error .input-city .ant-select-selection--single .ant-select-selection__rendered{
    margin-left: -4px;
    margin-top: -2px;
}
.has-error .input-city .ant-select-selection--single .ant-select-selection__rendered{
    margin-left: 0px;
    margin-top: -4px;
}
.input-city .ant-select-selection--single .ant-select-selection__rendered{
    margin-left: 0;
}
.input-gender .ant-select-selection__placeholder, .ant-select-search__field__placeholder .anticon > *{
    font-size: 20px;
    margin-left: 3px;
}
.input-gender .ant-select-selection-selected-value .anticon > *{
    font-size: 20px;
    margin-left: 3px; 
}
.place-icon{
    position: absolute;
    left: 35px;
    top: 380px;
    z-index: 2;
}
/* .input-normal-born .ant-input-prefix{
    left: 12px;
    height: 40px;
} */
.born__form{
    position: relative;
}
/* .born__form .ant-input-affix-wrapper .ant-input-prefix{
    height: 40px;
} */
.born__form .ant-form-item-children{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.gender-both{
    background: url('/both.svg');
    width: 40px;
    height: 40px;
    background-size: 100% 100%;
    background-position: center center;
}
.gender-man{
    background: url('/man.svg');
    width: 40px;
    height: 40px;
    background-size: 100% 100%;
    background-position: center center;
}
.gender-women{
    background: url('/women.svg');
    width: 40px;
    height: 40px;
    background-size: 100% 100%;
    background-position: center center;
}
.date-icon{
    position: absolute;
    background: url('/calendar.png');
    width: 25px;
    height: 25px;
    background-size: 100% 100%;
    background-position: center center;
    z-index: 1;
    top: 8px;
    left: 11px;
}
.register-wrapper .ant-select-arrow{
    display: none;
}
.has-error input#register_born{
    border: 2px solid #f5222d;
}
.has-error .ant-select-selection.ant-select-selection--single{
    border: 2px solid #f5222d;
}
.reward-tab-warpper .ant-tabs-nav{
    width: 100% !important;
}
.reward-tab-warpper .ant-tabs-nav-container{
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0), 0 4px 5px 0 rgba(0, 0, 0, 0.0), 0 1px 10px 0 rgba(0, 0, 0, 0.10)
}
.reward-tab-warpper .ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane{
    background-color: #F4F4F4;
}
.reward-tab-warpper .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
    text-align: center;
    width: 50% !important;
    margin-right: 0 !important;
    color: #01A69D;
    font-weight: 500;
    background-color: #F4F4F4;
}
.reward-tab-warpper .ant-tabs.ant-tabs-card .ant-tabs-card-bar{
    background-color: #F4F4F4 !important;
    margin-bottom: 0;
}
.reward-tab-warpper .ant-tabs-tab{
    border-color: transparent !important;
}
.reward-tab-warpper .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
    background-image: linear-gradient(#45E2D9,#01A69D);
    background-color: #ffffff !important;
    color: #ffffff !important;
}
.reward-tab-warpper .ant-tabs-nav .ant-tabs-ink-bar{
 background-color: transparent !important;
}
.reward-tab-warpper{
    background-color: #F4F4F4;
}
.countdown{
    text-align: center;
    background-color: #F4F4F4;
}
.reward-tab{
    width: 100%;
}
.loading-artist {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    flex-direction: column
}
.profile-infinite-container {
    overflow: auto;
    height: 100vh !important;
  }
body{
    font-family: 'Kanit', sans-serif;
    color: #F4F4F4F4;
    font-weight: 300;
    background-color: #FFFFFF;
}
/* .ant-btn-primary{
    color: #fff;
    background-image: linear-gradient(#45E2D9,#01A69D);
    background-color: transparent !important;
    border-color: transparent !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
} */
.ant-layout-header{
    position: relative !important;
}
.ant-modal-footer{
    border-top: 0 !important;
}
.button-center{
    text-align: center;
}
.ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 50px;
    padding-left: 50px;
}
.ant-layout{
    background: #FFFFFF !important;
}
.ant-message{
    top:200px !important;
}
.mt-1{
    margin-top: 10px;
}
.mt-2{
    margin-top: 20px;
}
.mb-1{
    margin-top: 10px;
}
.mb-2{
    margin-top: 20px;
}
.w-100{
    width: 100%;
}
.text-center{
    text-align: center;
}
.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}
.text-dark{
    color : #000000 !important;
}
.ant-spin-nested-loading > div > .ant-spin {
    top: 220px;
}
.swal2-title{
    color: #000000 !important;
}
.swal2-content{
    color: #6B6B6B;
}
.ant-modal-close-x{
    display: none;
}
.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.avatar__user{
    position: absolute;
    text-align: center;
    margin-bottom: 30px;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: -89px;
    left: 50%;
}
.edit-icon{
    position: absolute;
    top: 20px;
    padding: 4px 6px;
    background-color: #000000;
    right: -8px;
    border-radius: 50%;
    line-height: normal;
    color: white;
    border: 2px solid;
    font-size: 12px;
}
.edit-icon .material-icons{
    font-size: 16px;
}
.avatar-user{
    border: 4px solid;
}
.card-form{
    padding-top: 45px;
    position: relative;
}
.container{
    padding: 0px 15px;
    background-color: '#FFFFFF';
}
.font-title{
    padding-top: 30px;
    font-size: 34px;
    font-weight: 500;
    line-height: 1.25;
}
.text-center{
    text-align: center;
}
.text-center h4{
    font-size: 18px;
    font-weight: 100;
} 
.img-gift{
    padding-top: 80px;
}
.profile-detail{
    background-color: #F4F4F4;
}
.profile-detail .card-form .avatar__user{
    top: -89px;
}
.point-icon{
    background-color: #FFD93E;
    color: white;
    line-height: 1;
    display: inline-block;
    margin-right: 10px;
    border-radius: 50%;
    padding: 2px 3px 1px 3px;
}
.point-icon i{
    font-size: 11px;
}
.profile-detail .point-material{
    position: absolute;
    box-sizing: border-box;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    padding: 5px 10px;
    border-radius: 23px;
    z-index: 2;
    display: inline-block;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top:11%;
    background-color: #ffffff;
}
.point-material h4{
    display: inline-block;
    margin-bottom: 0;
}
.detail-card{
    margin-left: 15px;
    margin-right: 15px;
    box-sizing: border-box;
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .10), 0 1px 10px 0 rgba(0, 0, 0, .10);
    z-index: 1;
    border-radius: 10px;
}
.detail-box{
    padding: 0px;
}
/* favArtistComponent */
.artist-card{
    padding-top: 70px;
    margin-top: -85px;
    border: 0;
}
/* .point-warpper {
    min-height: calc(100vh - 325px);
} */
.point-warpper .ant-tabs-nav{
    width: 100% !important;
}
.point-warpper .ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane{
    background-color: #F4F4F4;
}
.point-warpper .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
    text-align: center;
    width: 50% !important;
    margin-right: 0 !important;
    color: #01A69D;
    font-weight: 500;
    background-color: #F4F4F4;
    border: 0;
    border-radius: 0;
}
.point-warpper .ant-tabs.ant-tabs-card .ant-tabs-card-bar{
    background-color: #F4F4F4 !important;
    margin-bottom: 0;
}
.point-warpper .ant-tabs-tab{
    border-color: transparent !important;
}
.point-warpper .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
    background-image: linear-gradient(#45E2D9,#01A69D);
    background-color: #ffffff !important;
    color: #ffffff !important;
}
.point-warpper .ant-tabs-nav .ant-tabs-ink-bar{
 background-color: transparent !important;
}
.point-tap{
    width: 100%;
}
.point-history{
    padding: 15px;
}
.card-history-point{
    margin-bottom: 15px;
    border-radius: 20px;
    box-sizing: border-box;
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .10), 0 1px 10px 0 rgba(0, 0, 0, .10);
}
.card-history-point .ant-card-body{
   padding: 15px 10px;
}
.card-history-point .ant-card-body .ant-avatar-image{
    border-radius: 15px;
}
.card-history-point .ant-card-body .ant-card-meta-detail {
    padding-top: 5px;
}
.card-history-point .ant-card-body .ant-card-meta-detail .ant-card-meta-description{
    color: #01A69D;
    font-weight: 500;
}
.card-history-tab{
    margin-bottom: 15px;
    border-radius: 20px;
    box-sizing: border-box;
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .10), 0 1px 10px 0 rgba(0, 0, 0, .10);
}
.card-history-tab .ant-card-body{
   padding: 15px 10px;
}
.card-history-tab .ant-card-body .ant-avatar-image{
    border-radius: 15px;
}
.card-history-tab .ant-card-body .ant-card-meta-detail {
    padding-top: 5px;
}
.card-history-tab .ant-card-body .ant-card-meta-detail .ant-card-meta-description{
    color: rgb(168, 168, 168);
    font-weight: 500;
}
.detail-text,.detail-text h4{
    overflow: hidden;
    zoom: 1;
}
.detail-text i,.detail-text h4{
    display: table-cell;
    vertical-align: top;
}
.detail-text i{
    vertical-align: middle;
}
.detail-text h4{
    vertical-align: middle;
    padding-left: 10px;
    color: #6B6B6B;
    font-weight: 400;
}
.detail-text{
    padding-bottom: 15px;
}
.artist-card-text h4{
    padding-top: 5px;
    margin-bottom: 0;
    color: #6B6B6B;
    font-weight: 400;
}
.point-tap .artist-name-card {
    padding: 15px;
    /* height: 380px;
    overflow: scroll; */
}
.artist-list .avatar-user img{
    width: 100%;
    height: 100%;
}
.artist-list .avatar-user{
    width: 50px;
    height: 50px;
}
@media only screen and (min-width: 700px) {
    .artist-list .avatar-user{
        width: 100px;
        height: 100px;
    }
  }
@media only screen and (min-width: 1000px) {
.artist-list .avatar-user{
    width: 125px;
    height: 125px;
}
}
.demo-infinite-container {
    /* border: 1px solid #e8e8e8; */
    /* border-radius: 4px; */
    overflow: auto;
    /* padding: 8px 24px; */
    height: 300px;
}
.demo-loading-container {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
}
@media only screen and (min-width: 700px) {
    .demo-infinite-container {
        overflow: auto !important;
        height: 390px !important;
    }
}
@media only screen and (min-width: 1000px) {
    .demo-infinite-container {
        overflow: auto !important;
        height: 705px !important;
    }
}
.point-warpper{
    background-color: #F4F4F4 !important;
}
.empty-point {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    flex-direction: column
}
.empty-point h4{
    color: #6B6B6B;
}
@media only screen and (min-width: 700px) {
    .empty-point {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 400px;
        flex-direction: column
    }
}
@media only screen and (min-width: 1000px) {
    .empty-point {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 720px;
        flex-direction: column
    }
}
.point-wrapper{
    background-color: '#FFFFFF';
}
.point-title{
    display: flex;
    background-color: #FECE00;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 60px;
}
.point-title span{
    font-size: 20px;
    font-weight: 400;
    color: #000000;
}
.product-img{
    width: 100%;
}
.product-img img{
    width: 100%;
}
.product-text{
    margin: 20px 30px 40px 30px;
}
.product-button{
    padding: 0px 10px;
}
.product-close-button{
  margin: 0 !important;
}
.add-point-wrapper{
    margin: 30px 20px;
}
.add-point-button{
    margin-top: 15px;
}
.btn-modal{
    padding: 0 15px;
}
.point-modal .ant-modal-body h1{
    text-align: center;
    font-size: 18px;
}
.point-modal .ant-modal-body h3{
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    color: #6B6B6B;
}
.all-point-wrapper .ant-card{
    border-radius: 5%;
    border-top: 0;
}
/* .all-point-wrapper .reward-card{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    height: auto!important;
} */
.all-point-wrapper .reward-card-img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-radius: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slice-card-img{
  display: block;
  border-top-right-radius: 5%;
  border-top-left-radius: 5%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slick-list{
  border-top-right-radius: 5%;
  border-top-left-radius: 5%;
}
.card-reward{
    position: initial;
    margin-bottom: 0;
}
.card-reward .slice-point-text{
  position: relative;
  box-sizing: border-box;
  /* background-color: black; */
  /* box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12); */
  padding: 5px 10px;
  border-radius: 23px;
  z-index: 2;
  display: inline-block;
  right: 19%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top:-170px;
  background-color: #ffffff;
}
.card-reward .slice-point-text h4{
  display: inline-block;
  margin-bottom: 0;
  color: #ffffff;
  font-size: 12px;
}
@media only screen and (min-width: 500px) {
  .card-reward .slice-point-text {
    top:-230px;
  }
}
@media only screen and (min-width: 600px) {
  .card-reward .slice-point-text {
    top:-290px;
  }
}
@media only screen and (min-width: 700px) {
  .card-reward .slice-point-text {
    top:-350px;
    right: 10%;
  }
  .card-reward .point-text{
    left: 90% !important;
}
}
@media only screen and (min-width: 750px) {
  .card-reward .slice-point-text {
    top:-380px;
  }
}
@media only screen and (min-width: 800px) {
  .card-reward .slice-point-text {
    top:-410px;
  }
}
@media only screen and (min-width: 900px) {
  .card-reward .slice-point-text {
    top:-470px;
  }
}
@media only screen and (min-width: 1000px) {
  .card-reward .slice-point-text {
    top:-530px;
  }
}
@media only screen and (min-width: 1000px) {
  .card-reward .slice-point-text {
    display: none;
  }
}
.card-reward .slice-point-text h4{
  display: inline-block;
  margin-bottom: 0;
  color: #ffffff;
  font-size: 12px;
}
.card-reward .point-text{
    position: relative;
    box-sizing: border-box;
    /* background-color: black; */
    /* box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12); */
    padding: 5px 10px;
    border-radius: 23px;
    z-index: 2;
    display: inline-block;
    left: 80%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top:-210px;
    background-color: #ffffff;
}
.card-reward .point-text h4{
    display: inline-block;
    margin-bottom: 0;
    color: #ffffff;
    font-size: 12px;
}
.all-point-wrapper .card-reward .ant-card-body{
    padding: 0;
    text-align: center;
    height: 225px;
    border-top-color: transparent;
    overflow: hidden;
}
.all-point-wrapper .reward-card {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    height: auto!important;
    border-top-right-radius: 5%;
    border-top-left-radius: 5%;
}
.slice-text{
    padding: 0 20px 0px 20px;
    /* margin-top: -20px; */
}
.slice-point{
    padding: 0px 20px 20px 20px;
}
.slice-text span{
    font-weight: 400;
    font-size: 18px;
    color: #000000;
}
.slice-point span{
    font-weight: 400;
    font-size: 14px;
    color: #6b6b6b;
}
.point-card-text{
    padding: 0px 0px 0px 0px;
    margin-top: -20px;
}
.point-card-point{
    padding: 0px 0px 20px 0px;
    margin-bottom: 20px;
}
.point-card-text span{
    font-weight: 400;
    font-size: 18px;
    color: #000000;
}
.point-card-point span{
    font-weight: 400;
    font-size: 14px;
    color: #6b6b6b;
}
.slice-card .ant-card-body{
    padding: 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 5%;
}

.all-point-wrapper .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
    /* background: darkorange !important; */
    border-color: transparent;
    border-radius: 50%;
}
.all-point-wrapper .ant-radio-inner{
    background-color: black;
}
.ant-radio-inner::after{
    top: -1px;
    left: -1px;
    display: table;
    width: 115%;
    height: 115%;
    background-color: #FECE00 !important;
}
.product-text h1{
    font-size: 16px;
}
.product-text h3{
    color: #01A69D;
    font-size: 16px;
}
.product-text h4{
    font-size: 14px;
    font-weight: 400;
    color: #6B6B6B;
}
.add-point-wrapper h3{
    font-size: 18px;
    font-weight: 400;
    color: #7A7A7A;
}
.point-modal{
    width: 300px;
    -webkit-transform-origin: 229px 499.188px;
            transform-origin: 229px 499.188px;
}


/* body {
    font-family: sans-serif;
    display: grid;
    height: 100vh;
    place-items: center;
  } */ 
  
  .base-timer {
    position: relative;
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }
  
  .base-timer__svg {
    -webkit-transform: scaleX(+1);
            transform: scaleX(+1);
  }
  
  .base-timer__circle {
    fill: none;
    stroke: none;
  }
  
  .base-timer__path-elapsed {
    stroke-width: 5px;
    stroke: #4E4E4E;
  }
  
  .base-timer__path-remaining {
    stroke-width: 5px;
    stroke-linecap: round;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
  }
  
  .base-timer__path-remaining.green {
    color: #FECE00;
  }
  
  .base-timer__path-remaining.orange {
    color: #FECE00;
  }
  
  .base-timer__path-remaining.red {
    color: #FECE00;
  }
  
  .base-timer__label {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: black;
    font-weight: 400;
  }

  /* hjhjkh */

  .base-timer2 {
    position: relative;
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }
  
  .base-timer__svg2 {
    -webkit-transform: scaleX(+1);
            transform: scaleX(+1);
  }
  
  .base-timer__circle2 {
    fill: none;
    stroke: none;
  }
  
  .base-timer__path-elapsed2 {
    stroke-width: 5px;
    stroke: #4E4E4E;
  }
  
  .base-timer__path-remaining2 {
    stroke-width: 5px;
    stroke-linecap: round;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
  }
  
  .base-timer__path-remaining2.green {
    color: #FECE00;
  }
  
  .base-timer__path-remaining2.orange {
    color: #FECE00;
  }
  
  .base-timer__path-remaining2.red {
    color: #FECE00;
  }
  
  .base-timer__label2 {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-weight: 400;
  }

  .base-timer__min {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 40px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  .base-timer__sec {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 40px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  .countdown{
      text-align: center;
      background-color: #F4F4F4;
  }
  .random-code{
      margin: 0 !important;
      text-align: center;
      padding: 30px;
      background: #F4F4F4;
  }
  .random-code h4{
      font-size: 14px;
      font-weight: 300;
  }
  .random-code h1{
    font-weight: 400;
    font-size: 28px;
}
.demo-infinite-container {
  overflow: auto;
  min-height: 100vh !important;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

