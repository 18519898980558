.point-wrapper{
    background-color: '#FFFFFF';
}
.point-title{
    display: flex;
    background-color: #FECE00;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 60px;
}
.point-title span{
    font-size: 20px;
    font-weight: 400;
    color: #000000;
}
.product-img{
    width: 100%;
}
.product-img img{
    width: 100%;
}
.product-text{
    margin: 20px 30px 40px 30px;
}
.product-button{
    padding: 0px 10px;
}
.product-close-button{
  margin: 0 !important;
}
.add-point-wrapper{
    margin: 30px 20px;
}
.add-point-button{
    margin-top: 15px;
}
.btn-modal{
    padding: 0 15px;
}
.point-modal .ant-modal-body h1{
    text-align: center;
    font-size: 18px;
}
.point-modal .ant-modal-body h3{
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    color: #6B6B6B;
}
.all-point-wrapper .ant-card{
    border-radius: 5%;
    border-top: 0;
}
/* .all-point-wrapper .reward-card{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    height: auto!important;
} */
.all-point-wrapper .reward-card-img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-radius: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.slice-card-img{
  display: block;
  border-top-right-radius: 5%;
  border-top-left-radius: 5%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.slick-list{
  border-top-right-radius: 5%;
  border-top-left-radius: 5%;
}
.card-reward{
    position: initial;
    margin-bottom: 0;
}
.card-reward .slice-point-text{
  position: relative;
  box-sizing: border-box;
  /* background-color: black; */
  /* box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12); */
  padding: 5px 10px;
  border-radius: 23px;
  z-index: 2;
  display: inline-block;
  right: 19%;
  transform: translateX(-50%);
  top:-170px;
  background-color: #ffffff;
}
.card-reward .slice-point-text h4{
  display: inline-block;
  margin-bottom: 0;
  color: #ffffff;
  font-size: 12px;
}
@media only screen and (min-width: 500px) {
  .card-reward .slice-point-text {
    top:-230px;
  }
}
@media only screen and (min-width: 600px) {
  .card-reward .slice-point-text {
    top:-290px;
  }
}
@media only screen and (min-width: 700px) {
  .card-reward .slice-point-text {
    top:-350px;
    right: 10%;
  }
  .card-reward .point-text{
    left: 90% !important;
}
}
@media only screen and (min-width: 750px) {
  .card-reward .slice-point-text {
    top:-380px;
  }
}
@media only screen and (min-width: 800px) {
  .card-reward .slice-point-text {
    top:-410px;
  }
}
@media only screen and (min-width: 900px) {
  .card-reward .slice-point-text {
    top:-470px;
  }
}
@media only screen and (min-width: 1000px) {
  .card-reward .slice-point-text {
    top:-530px;
  }
}
@media only screen and (min-width: 1000px) {
  .card-reward .slice-point-text {
    display: none;
  }
}
.card-reward .slice-point-text h4{
  display: inline-block;
  margin-bottom: 0;
  color: #ffffff;
  font-size: 12px;
}
.card-reward .point-text{
    position: relative;
    box-sizing: border-box;
    /* background-color: black; */
    /* box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12); */
    padding: 5px 10px;
    border-radius: 23px;
    z-index: 2;
    display: inline-block;
    left: 80%;
    transform: translateX(-50%);
    top:-210px;
    background-color: #ffffff;
}
.card-reward .point-text h4{
    display: inline-block;
    margin-bottom: 0;
    color: #ffffff;
    font-size: 12px;
}
.all-point-wrapper .card-reward .ant-card-body{
    padding: 0;
    text-align: center;
    height: 225px;
    border-top-color: transparent;
    overflow: hidden;
}
.all-point-wrapper .reward-card {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
    height: auto!important;
    border-top-right-radius: 5%;
    border-top-left-radius: 5%;
}
.slice-text{
    padding: 0 20px 0px 20px;
    /* margin-top: -20px; */
}
.slice-point{
    padding: 0px 20px 20px 20px;
}
.slice-text span{
    font-weight: 400;
    font-size: 18px;
    color: #000000;
}
.slice-point span{
    font-weight: 400;
    font-size: 14px;
    color: #6b6b6b;
}
.point-card-text{
    padding: 0px 0px 0px 0px;
    margin-top: -20px;
}
.point-card-point{
    padding: 0px 0px 20px 0px;
    margin-bottom: 20px;
}
.point-card-text span{
    font-weight: 400;
    font-size: 18px;
    color: #000000;
}
.point-card-point span{
    font-weight: 400;
    font-size: 14px;
    color: #6b6b6b;
}
.slice-card .ant-card-body{
    padding: 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 5%;
}

.all-point-wrapper .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
    /* background: darkorange !important; */
    border-color: transparent;
    border-radius: 50%;
}
.all-point-wrapper .ant-radio-inner{
    background-color: black;
}
.ant-radio-inner::after{
    top: -1px;
    left: -1px;
    display: table;
    width: 115%;
    height: 115%;
    background-color: #FECE00 !important;
}
.product-text h1{
    font-size: 16px;
}
.product-text h3{
    color: #01A69D;
    font-size: 16px;
}
.product-text h4{
    font-size: 14px;
    font-weight: 400;
    color: #6B6B6B;
}
.add-point-wrapper h3{
    font-size: 18px;
    font-weight: 400;
    color: #7A7A7A;
}
.point-modal{
    width: 300px;
    transform-origin: 229px 499.188px;
}


/* body {
    font-family: sans-serif;
    display: grid;
    height: 100vh;
    place-items: center;
  } */ 
  
  .base-timer {
    position: relative;
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }
  
  .base-timer__svg {
    transform: scaleX(+1);
  }
  
  .base-timer__circle {
    fill: none;
    stroke: none;
  }
  
  .base-timer__path-elapsed {
    stroke-width: 5px;
    stroke: #4E4E4E;
  }
  
  .base-timer__path-remaining {
    stroke-width: 5px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
  }
  
  .base-timer__path-remaining.green {
    color: #FECE00;
  }
  
  .base-timer__path-remaining.orange {
    color: #FECE00;
  }
  
  .base-timer__path-remaining.red {
    color: #FECE00;
  }
  
  .base-timer__label {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: black;
    font-weight: 400;
  }

  /* hjhjkh */

  .base-timer2 {
    position: relative;
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }
  
  .base-timer__svg2 {
    transform: scaleX(+1);
  }
  
  .base-timer__circle2 {
    fill: none;
    stroke: none;
  }
  
  .base-timer__path-elapsed2 {
    stroke-width: 5px;
    stroke: #4E4E4E;
  }
  
  .base-timer__path-remaining2 {
    stroke-width: 5px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
  }
  
  .base-timer__path-remaining2.green {
    color: #FECE00;
  }
  
  .base-timer__path-remaining2.orange {
    color: #FECE00;
  }
  
  .base-timer__path-remaining2.red {
    color: #FECE00;
  }
  
  .base-timer__label2 {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    font-weight: 400;
  }

  .base-timer__min {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 40px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  .base-timer__sec {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 40px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  .countdown{
      text-align: center;
      background-color: #F4F4F4;
  }
  .random-code{
      margin: 0 !important;
      text-align: center;
      padding: 30px;
      background: #F4F4F4;
  }
  .random-code h4{
      font-size: 14px;
      font-weight: 300;
  }
  .random-code h1{
    font-weight: 400;
    font-size: 28px;
}
.demo-infinite-container {
  overflow: auto;
  min-height: 100vh !important;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
