@import url('https://fonts.googleapis.com/css?family=Kanit:300,400,500,600,700,900&display=swap');
body{
    font-family: 'Kanit', sans-serif;
    color: #F4F4F4F4;
    font-weight: 300;
    background-color: #FFFFFF;
}
/* .ant-btn-primary{
    color: #fff;
    background-image: linear-gradient(#45E2D9,#01A69D);
    background-color: transparent !important;
    border-color: transparent !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
} */
.ant-layout-header{
    position: relative !important;
}
.ant-modal-footer{
    border-top: 0 !important;
}
.button-center{
    text-align: center;
}
.ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 50px;
    padding-left: 50px;
}
.ant-layout{
    background: #FFFFFF !important;
}
.ant-message{
    top:200px !important;
}
.mt-1{
    margin-top: 10px;
}
.mt-2{
    margin-top: 20px;
}
.mb-1{
    margin-top: 10px;
}
.mb-2{
    margin-top: 20px;
}
.w-100{
    width: 100%;
}
.text-center{
    text-align: center;
}
.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}
.text-dark{
    color : #000000 !important;
}
.ant-spin-nested-loading > div > .ant-spin {
    top: 220px;
}
.swal2-title{
    color: #000000 !important;
}
.swal2-content{
    color: #6B6B6B;
}
.ant-modal-close-x{
    display: none;
}